import React from 'react';

const Navbar = () => {
    return (
        <div className='navbar'>
            <a href="./"><img alt="logo of this is crispin" src="assets/logo_large.png"/></a>
        </div>
    )
}

export default Navbar;
